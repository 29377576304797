.profile{
    margin: 50px 0;
}
.profile-slider{
    .slick-track{
        display: flex;
        .slick-slide{
            margin-right: 24px;
        }
    }
}
.profile{
    h3{
        margin-bottom: 21px;
        font-size: 18px;
        font-weight: 700;
    }

    .profile-h3{
        margin-bottom: 4px;
    }
}
.profile-card{
    padding: 18px 24px;
    border-radius: 16px;
    background: var(--profileCard);
    display: flex;
    flex-direction: column;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: right;
    height: 160px;

    .profile-card-price{
        font-size: 24px;
        color: var(--mainColor);
        font-weight: 600;
    }
    .profile-card-title{
        font-weight: 500;
        font-size: 20px;
    }
    .profile-card-text{
        font-size: 16px;
        margin-top: auto;
    }
}
.profile-card1{
    background: url('../../images/pig.png'), var(--profileCard);
}
.profile-card2{
    background: url('../../images/mail.png'), var(--profileCard);
}
.profile-card3{
    background: url('../../images/loc.png'), var(--profileCard);
}
.tab-links{
    .tab-links-item{
        a{
            background: #fff;
            color: #232323;
            padding: 10px 8px;
            border-radius: 8px;
            margin-bottom: 16px;
            display: block;
            font-size: 16px;
            transition: .3s;

            i{
                font-size: 20px;
                margin-right: 8px;
            }
        }
        &.pd-left{
            a{
                padding-left: 30px; 
            }
        }
        &.active{
            a{
                background: #090909;
                color: #fff;
            }
        }
        &:hover{
            a{
                background: #090909;
                color: #fff;
            }
        }
    }
}
.profile-dashboard{
    //background-color: #F9F9F9;
    //padding: 24px;
    border-radius: 16px;
    position: relative;
    height: 100%;

    .desk-detail{
        display: flex;
        justify-content: center;
        margin-bottom: 32px;
        margin-top: -20px;
        h3{
            display: none;
        }

    }

    .input{
        background: #fff;
    }

    h4{
        color: #232323;
        font-size: 18px;
        font-weight: 700;
    }
    .flex{
        &:not(:last-child){
            margin-bottom: 20px;
        }
    }
}
.profile-white{
    background: #fff;
    padding: 24px;
}
.align-center{
    align-items: center;
}
.profile-dashboard-wrapper{
    display: flex;
    flex-direction: column;
    gap: 16px;
    span{
        color: #9D9D9D;
        font-size: 12px;
        margin-bottom: 12px;
    }
    p{
        color: #232323;
        font-size: 18px;
    }
}
.span{
    margin-left: auto;
    color: #47A7FF;
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 16px;
    i{
        margin-left: 4px;
    }
}
.order-wrapper{
    display: flex;
    justify-content: space-between;
    margin-top: 4px;
    color: #7B7B7B;
    .date{
        font-size: 12px;
        margin-bottom: 16px;
    }

    p{
        font-size: 16px;
        color: #474747;
        margin-bottom: 8px;
    }
}
.order-card{
    background-color: #fff;
    border-radius: 16px;
    padding: 16px;
    margin-bottom: 20px;

    &-top{
        display: flex;
        justify-content: space-between;
    }
    .qr-code{
        border-radius: 8px;
        background: rgba(51, 205, 85, 0.10);
        padding: 4px;

        i{
            font-size: 24px;
            color: #33CD55;
        }
    }

    h5{
        margin-bottom: 16px;
        color: #232323;
        font-size: 16px;
        font-weight: 500;
    }
}
.order-card-state{
    padding: 2px 8px;
    border-radius: 27px;
    color: #fff;
    background-color: #00A511;
    width: fit-content;
    height: fit-content;
}
.order-text{
    font-size: 16px;
    color: #232323;
    margin-bottom: 8px;
}
.order-price{
    font-weight: 400;
    font-size: 20px;
    color: #232323;
    white-space: nowrap;
}
.address-card{
    padding: 16px;
    background-color: #FFF;
    border-radius: 16px;

    .text{
        margin-bottom: 10px;
        font-weight: 500;
        span{
            color: #7B7B7B;
            font-weight: 400;
        }
    }
    .address-card-top{
        display: flex;
        justify-content: end;
        gap: 10px;
        font-size: 18px;
    }
}
.icon-pen{
    color: #47A7FF;
    cursor: pointer;
}
.icon-trash{
    color: #E82830;
    cursor: pointer;
}
.red{
    color: var(--mainColor);
    font-weight: 600;
    cursor: pointer;
}
.bonus-card{
    background-color: #fff;
    border-radius: 16px;
    padding: 16px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    cursor: pointer;

    .title{
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 8px;
    }
    .date{
        color: #7B7B7B;
        font-size: 12px;
        font-weight: 500;
    }
    a{
        font-weight: 600;
        display: flex;
        align-items: center;

        &:hover{
            color: var(--mainColor);
        }

        i{
            font-size: 20px;
        }
    }
    .bonus-state{
        border-radius: 27px;
        color: #52BB2D;
        font-weight: 600;
        height: fit-content;
        font-size: 16px;

        &.danger{
            color: red;
        }
    }
}
.order-link-wrapper{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 9px;
}
.order-link{
    padding: 4px 22px;
    background: #DDD;
    border-radius: 30px;
    text-align: center;
    color: #7B7B7B;
    margin: 0 0 24px;
    font-size: 16px;

    &.active{
        background: var(--mainGradient);
        color: #fff;
    }
}
.order-products{
    display: flex;
    gap: 4px;
    justify-content: end;
    margin-top: auto;
    img{
        width: 30px;
        height: 30px;
        border-radius: 4px;
    }
    .order-card-box{
        background: var(--accentColor);
        height: 30px;
        width: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        color: #fff;
        font-size: 16px;
        font-weight: 600;
    }
}

.delete-container{
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #E82830;
    cursor: pointer;
    //display: none;
    i{
        margin-left: 4px;
    }
}
.order-list{
    color: #7B7B7B;
    font-weight: 400;
    margin-top: 20px;
    li:not(:last-child){
        margin-bottom: 15px;
    }
    span{
        color: #232323;
        font-size: 16px;
    }
}
.order-card-mini{
    border-radius: 16px;
    background: #FFF;
    box-shadow: 0px -2px 12px 0px rgba(0, 0, 0, 0.05);
    padding: 8px;
    display: grid;
    grid-template-columns: 100px 1fr;
    gap: 12px;
    margin-bottom: 20px;
    img{
        width: 100px;
        height: 100px;
        object-fit: contain;
        border: 1px solid #F9F9F9;
        border-radius: 8px;
    }
    .flex{
        margin-top: 16px;
    }
}
.order-total-box{
    border-radius: 16px;
    border: 1px solid var(--mainColor);
    background: #FFF;
    padding: 16px;
    width: fit-content;
    margin-left: auto;

    span{
        margin-left: 40px;
    }
    .flex:not(:last-child){
        margin-bottom: 10px;
    }
    .flex{
        align-items: center;
    }
}
.profile-text{
    font-weight: 400;
    span{
        color: #7B7B7B;
    }
    p{
        color: #232323;
    }
}
.order-status{
    border-radius: 27px;
    padding: 2px 8px;
    color: #fff;
    background: #00A511;
    width: fit-content;
    font-weight: 600;
    height: fit-content;
}
.order-statuses{
    position: relative;
    background: #DDDDDD;
    height: 2px;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    margin: 10px 0 40px;

    .order-statuses-item{
        width: 100%;
        height: 2px;
        background: #DDDDDD;
        position: relative;

        span{
            display: none;
        }

        &::before{
            content: "";
            position: absolute;
            right: 0;
            top: -7px;
            width: 16px;
            height: 16px;
            border-radius: 50%;
            background: #DDDDDD;
        }

        &.active{
            background: var(--mainColor);

            &::before{
                background: var(--mainColor);
            }

            span{
                display: flex;
                color: var(--mainColor);
                position: absolute;
                right: -14px;
                top: 8px;
            }
        }
    }
}
.profile-bonus{
    border-radius: 16px;
    border: 1px solid #DDD;
    background: #FFF;
    padding: 24px;
    margin-bottom: 16px;

    &-middle{
        margin-bottom: 44px;
        margin-top: 16px;
    }

    &-top{
        color: #232323;
        font-size: 16px;
    }

    &-bottom{
        color: #7B7B7B;
        font-size: 16px;
    }

    .profile-qcode{
        border-radius: 8px;
        background: rgba(51, 205, 85, 0.10);
        padding: 6px;
        color: #090909;
        font-size: 14px;
        font-weight: 500;

        i{
            font-size: 18px;
            margin-right: 10px;
        }
    }

    .bonus{
        color: #090909;
        font-size: 24px;
        font-weight: 700;
        display: flex;
        align-items: center;

        i{
            margin-right: 5px;
            font-size: 28px;
        }
    }
}
.profile-info{
    position: relative;
    i{
        color: #A0A0A0;
        font-size: 20px;
        cursor: pointer;
    }

    &-content{
        position: absolute;
        right: 0;
        width: 200px;
        background: #fff;
        padding: 6px;
        border-radius: 8px;
        opacity: 0;
        visibility: hidden;

        &.active{
            opacity: 1;
            visibility: visible;
        }
    }
}

.profile-grid{
    display: grid;
    grid-template-columns: 3fr 7fr;
    gap: 50px;
}
.button-back{
    i{
        display: none;
    }
    .icon-plus{
        display: inline;
    }
}
.mob-add-address{
    display: none;
}
.card-item-buttons{
    margin-left: auto;

    .icon-trash{
        display: none;
        margin-left: 8px;
    }
}
.qr-code{
    padding: 16px;
    background: #fff;
    border-radius: 16px;
    margin-bottom: 16px;
    h5{
        margin-bottom: 16px;
    }
    img{
        width: 124px;
        height: 124px;
        object-fit: contain;
    }
}
.profile-left{
    &-top{
        border-radius: 16px;
        background: #fff;
        padding: 24px;
        margin-bottom: 16px;

        .title{
            color: #283034;
            font-size: 24px;
            font-weight: 700;
            margin-bottom: 8px;
        }
        p{
            color: #A0A0A0;
            font-size: 18px;
            margin-bottom: 32px;
        }
        .logout{
            color: #FA4C2F;
            font-size: 14px;
            font-weight: 500;
            display: flex;
            align-items: center;
            cursor: pointer;

            i{
                font-size: 24px;
                margin-right: 8px;
            }
        }
    }
}
@media(max-width: 1200px){
    .profile{
        .order-card{
            .address-grid{
                grid-template-columns: 1fr;
            }
        }
    }
}
@media(max-width: 996px){
    .profile-grid{
        gap: 20px;
    }
}
.card-item-mob{
    display: none;
}
.profile-phone{
    color: rgba(35, 35, 35, 0.60);
    font-size: 16px;
    font-weight: 600;
}
@media(max-width: 767px){
    .address-grid{
        gap: 12px;
        .card-item-mob{
            display: inline;
        }
        .card-item-desk{
            display: none;
        }
        .card-grid, .mob-none{
            display: none;
        }
        .card-item-buttons{
            .icon-trash{
                display: inline;
            }
        }
        .address-card .card-item{
            margin: 0;
        }
        .address-card{
            border-radius: 8px;
            span{
                color: #232323;
            }
        }
    }
    .profile-grid{
        grid-template-columns: 1fr;
    }
    .profile-left{
        display: none;
    }
    .button-back{
        display: flex;
        align-items: center;
        margin-bottom: 26px;
        h3{
            margin: 0;
            margin: 0 auto;
            font-size: 20px;
        }
        i{
            font-size: 20px;
            cursor: pointer;
            display: block;
        }
    }
    .profile-dashboard{
        .red{
            display: none;
        }
        .input{
            background: #F9F9F9;
        }
    }
    .profile{
        padding-top: 16px;
    }
    .mob-add-address{
        display: flex;
        color: #7B7B7B;
        font-size: 14px;
        font-weight: 700;
        margin-top: 40px;
        align-items: center;

        i{
            font-size: 24px;
            margin-right: 4px;
        }
    }
    .profile-bonus{
        padding: 20px;

        .profile-bonus-middle{
            margin: 5px 0 13px;
        }
        .bonus{
            font-size: 18px;

            i{
                font-size: 20px;
            }
        }
    }
    .profile-bonus-bottom{
        font-size: 12px;
    }
    .profile-dashboard{
        //background: #fff;
        padding: 0;
    }
    .bonus-card{
        border-radius: 16px;
        background: #F9F9F9;
        margin-bottom: 16px;

        .title{
            font-size: 14px;
        }
    }
    .profile-white{
        background: #f3f3f3;
    }
    .profile{
        position: relative;
        background: #f3f3f3;
        top: -68px;
        z-index: 2;

        .main-btn{
            z-index: 2;
        }
    }
    .profile-right .dashboard{
        .profile-bonus{
            display: none;
        }
    }
}
.profile-login{
    text-align: center;
    h3{
        margin-bottom: 16px;
    }
    p{
        color: #282828;
        text-align: center;
        font-size: 16px;
        margin-bottom: 30px;
    }
    .main-btn{
        width: 100%;
        margin-bottom: 30px;
    }
}

.order-detail-card{
    border-radius: 16px;
    background: #FFF;
    box-shadow: 0px -2px 12px 0px rgba(0, 0, 0, 0.05);
    margin-bottom: 16px;
    padding-bottom: 4px;
    img{
        width: 107px;
        height: 107px;
        border-radius: 16px;
    }
    .order-detail-flex{
        display: flex;
        gap: 16px;

        h5{
            color: #232323;
            font-size: 14px;
            font-weight: 700;
        }
    }
    .order-detail-content{
        padding: 12px 12px 12px 0;
        display: flex;
        flex-direction: column;

        .price{
            display: flex;
            flex-direction: column;
            margin-top: auto;

            .title{
                color: var(--mainColor);
                font-size: 17px;
                font-weight: 600;
            }

            .old-price{
                color: #7B7B7B;
                font-size: 12px;
                font-weight: 400;
            }
        }
    }
    .additional{
        border-radius: 12px;
        background: #F9F9F9;
        padding: 8px;
        margin: 0 4px;
        display: flex;

        &:not(:last-child){
            margin-bottom: 4px;
        }

        p{
            color: #9CA5AF;
            font-size: 14px;
            font-weight: 400;
            margin-right: 4px;

            span{
                color: #222831;
                font-size: 14px;
                font-weight: 600;
            }
        }
    }
}
.order-card-middle{
    h5{
        margin-bottom: 20px;
    }
}
///support
/// 

.dashboard-right-sidebar{
    height: 100%;
}
.support-chat{
    &-wrapper{
        padding: 16px;
        background: #fff;
        border-radius: 8px;
        
    .block-center{
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    }
    &-item{
        font-size: 16px;
        font-weight: 500;
        border-radius: 16px 16px 16px 0px;
        background: #EAFFE0;
        padding: 8px 16px;
        max-width: 80%;

        &-wrapper{
            display: flex;
            flex-direction: column;
            gap: 10px;
            margin-bottom: 16px;

            .date{
                text-align: center;
            }
        }

        &.right{
            margin-left: auto;
            border-radius: 16px 16px 0px 16px;
        }

        .time{
            font-size: 12px;
            color: #7E7E7E;
            margin-top: 4px;
        }
    }
    &-content{
        height: 400px;
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        gap: 32px;

        &::-webkit-scrollbar {
            width: 5px;
            opacity: 0;
            position: absolute;
        }
        &::-webkit-scrollbar-track {
            background: #f1f1f1;
            box-shadow: 0 0 2px rgba(0, 0, 0, .2) inset;
        }
        &::-webkit-scrollbar-thumb {
            background: #c1c1c1;
            border-radius: 4px;
            border: 1px solid #f1f1f1;
        }
        &::-webkit-scrollbar-thumb:hover {
            background: #000000;
        }
    }
}
.send-msg{
    display: flex;
    gap: 8px;
}
.chat-input{
    outline: none;
    border: none;
    width: 100%;
    border-radius: 8px;
    background: #F9F9F9;
    padding: 12px;
}

.chat-send-button{
    border-radius: 8px;
    background: var(--mainColor);
    height: 42px;
    width: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.chat-send-file{
    height: 42px;
    width: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    position: relative;
    background: rgba(51, 205, 85, 0.10);

    input{
        width: 100%;
        width: 100%;
        position: absolute;
        opacity: 0;
        top: 0;
        left: 0;
    }
}
.file-item{
    display: flex;
    gap: 10px;
    width: 100%;
    .title{
        font-size: 16px;
        font-weight: 600;
    }
    .size{
        color: #9D9D9D;
    }
    .file-icon{
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background: #442EC8;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 24px;
        color: #fff;
    }
}
.images-flex{
    display: flex;
    flex-wrap: wrap;
    gap: 4px;

    img{
        width: 60px;
        height: 60px;

        &:nth-child(1){
            width: 100%;
            height: auto;
        }
    }
}
.chat-file-input{
    outline: none;
    border: none;
    border-bottom: 2px solid var(--mainColor);
    height: 44px;
    width: 100%;
}
.chat-file-button{
    color: var(--mainColor);
    font-weight: 500;
    font-size: 16px;
    margin-top: 20px;
    cursor: pointer;
    text-align: right;
}
.images-bottom{
    margin-top: 20px;
}

//review card

.review-card{
    background: #fff;
    padding: 16px;
    border-radius: 8px;
    margin-bottom: 10px;

    .d-flex{
        i{
            font-size: 18px;
            color: red;
            cursor: pointer;
        }
        margin-bottom: 10px;
    }

    &-flex{
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
    }
    &-product{
        display: flex;
        gap: 10px;

        img{
            width: 75px;
            height: 75px;
            border-radius: 8px;
        }
    }
    &-attachments{
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        margin-top: 10px;

        img{
            width: 54px;
            height: 54px;
            border-radius: 4px;
        }
    }
    p{
        font-size: 16px;
        margin-top: 10px;
    }
}