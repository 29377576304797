.time-select-modal{
    .modal-content{
        padding: 32px 16px;
    }
    h3{
        text-align: center;
        margin-bottom: 32px;
    }
    .close{
        right: 16px;
        color: #232323;
        top: 16px;
        font-size: 24px;
    }
}
.time-list{
    /*display: flex;
    flex-wrap: wrap;
    gap: 8px;
    margin-bottom: 24px;*/
    margin-bottom: 24px;
    &-item{
        padding: 8px 16px;
        border-radius: 50px;
        background: #F9F9F9;
        color: var(--mainColor);
        font-size: 14px;
        font-weight: 600;
        white-space: nowrap;
        cursor: pointer;

        &.active{
            background: var(--mainColor);
            color: #fff;
        }
    }
    .slick-track{
        display: flex;
        gap: 10px;
        //padding: 0 10px;
    }
    .slick-slider{
        padding: 0 12px;
    }
    .slick-next {
        right: 0;
        color: var(--mainColor);
        &:before{
            color: var(--mainColor);
        }
    }
    .slick-prev{
        left: 0;
        color: var(--mainColor);
        z-index: 11;
        &:before{
            color: var(--mainColor);
        }
    }
}
.input-select{
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-bottom: 32px;
    
    label{
        color: #232323;
        font-weight: 500;
    }

    .input{
        width: 100%;
    }
}
.error-time{
    color: #FF4A10;
    margin-top: 10px;
}