.top-button{
    width: 45px;
    height: 45px;
    background: #ababab;
    position: fixed;
    bottom: 20px;
    right: 20px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 26px;
    color: #fff;
    cursor: pointer;
    z-index: 50;

    i{
        margin-bottom: 2px;
    }
}