.city-modal{
    .modal-body{
        padding: 16px;
    }
    h3{
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 16px;
    }
    &-content{
        display: flex;
        flex-direction: column;
        margin-bottom: 8px;

        label{
            padding: 8px;
            color: #263238;
            font-size: 14px;
            font-weight: 500;
            display: flex;
            align-items: center;
            margin-bottom: 8px;

            input{
                width: 20px;
                height: 20px;
            }
        }
    }
    .main-btn{
        margin-top: 20px;
        width: 100%;
    }

    &-languages{
        margin-bottom: 30px;
        .languages-items{
            display: flex;
            gap: 16px;
            flex-wrap: wrap;
        }
        .languages-item{
            padding: 5px 16px;
            border-radius: 50px;
            background: #F9F9F9;
            color: #232323;
            font-weight: 500;
            line-height: 22px;
            cursor: pointer;

            &:hover{
                background: var(--mainColor);
                color: #fff;
            }

            &.active{
                background: var(--mainColor);
                color: #fff;
            }
        }
    }
}