.story-modal{
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.60);
    backdrop-filter: blur(10px);
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
}
.story-modal-content{
    height: 622px;
    background: transparent;
    border-radius: 16px;
    position: relative;
    display: flex;
    width: 381px;
    max-height: 100vh;
}
.story-content-card{
    display: none;

    &.active{
        display: block;
    }
}
.story-range{
    position: absolute;
    top: 10px;
    width: 100%;
    padding: 0 16px;
    display: flex;
    gap: 4px;
    z-index: 10;
}
.story-range-item{
    border-radius: 50px;
    background: rgba(255, 255, 255, 0.30);
    height: 4px;
    width: 100%;
    overflow: hidden;
    //box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.progress{
    width: 0;
    height: 4px;
    background: #fff;
    transition: width 1s linear;
}
.story-modal-stories{
    display: flex;
}
.story-modal-item{
    display: none;
    position: relative;

    &.active{
        display: block;
    }

    img{
        height: 622px;
        max-height: 100vh;
        border-radius: 16px;
    }
    .img-left, .img-right{
        position: absolute;
        z-index: 2;
        width: 50%;
        height: 100%;
        top: 0;
    }
    .img-left{
        left: 0;
    }
    .img-right{
        right: 0;
    }
    .story-content{
        position: relative;
        .main-btn{
            position: absolute;
            bottom: 16px;
            width: calc(100% - 32px);
            margin: 0 16px;
            z-index: 13;
        }
    }
}
.story-close{
    color: #fff;
    font-size: 20px;
    position: absolute;
    top: 20px;
    right: 34px;
    z-index: 10;
    cursor: pointer;
}
.story-button{
    font-size: 40px;
    color: #fff;
    position: absolute;
    top: calc(50% - 40px);
    cursor: pointer;
    transition: .3s;
    z-index: 1;

    &:hover{
        transform: scale(1.1);
    }

    &.left{
        left: -40px;
    }
    &.right{
        right: -40px;
    }
}
@media only screen and (max-height: 622px) and (min-width: 768px) {
    .story-modal-content{
        height: 100vh;
    }
    .story-modal-item{
        img{
            height: 100vh;
        }
    }
}

@media(max-width: 996px){
    .story-close{
        right: 20px;
    }
}

@media(max-width: 556px){
    .story-modal-content{
        height: 100vh;
        width: 100%;
    }
    .story-modal-item{
        img{
            height: 100vh;
            width: 100%;
        }
    }
    .story-button{
        background: rgba(0, 0, 0, 0.60);
        border-radius: 50%;
        width: 40px;
        height: 40px;
        font-size: 20px;
        display: flex;
        align-items: center;
        justify-content: center;

        &.left{
            left: 0;
        }
        &.right{
            right: 0;
        }
    }
}
.story-text{
    height: 100%;
    width: 100%;
    img{
        height: 100%;
        border-radius: 16px;
        width: 100%;
    }
}

.stories-modal-wrapper{
    height: 90vh;
    width: 50vh;
    max-height: 100vh;
}

@media(max-width: 767px){
    .stories-modal{
        padding: 0;
        margin: 0;

        .modal-dialog {
            padding: 0;
            margin: 0 auto;
            max-width: 100%;
        }
        .close{
            top: 7px;
        }
    }
    .stories-modal-wrapper{
        width: 100%;
        height: 100vh;
    }
    .story-text{
        img{
            object-fit: contain;
        }
    }
}