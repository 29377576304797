.login-modal{
    padding: 32px;
    background: #fff;
    border-radius: 16px;
    position: relative;

    h2{
        color: #30475E;
        margin-bottom: 16px;
    }

    p{
        margin-bottom: 16px;
        font-size: 16px;
        color: #9CA5AF;
    }

    .main-btn{
        width: 100%;
    }
}