.grid{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 16px;
}
.product-card{
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;
    cursor: pointer;
    background: #fff;
    border-radius: 8px;

    img{
        width: 100%;
        height: 225px;
        object-fit: cover;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
    }
    .card-relative{
        position: relative;
    }
    .modal-states{
        bottom: 15px;
    }
    h4{
        font-size: 16px;
    }
    .price{
        font-size: 18px;
        font-weight: 700;
    }
}

.product-flex{
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 10px 15px 15px;
}
.product-card-wrapper{
    .content{
        color:#9CA5AF;
        margin-bottom: 20px;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        display: -webkit-box;
        overflow: hidden;
        font-size: 12px;
    }
    .subtitle{
        color:#9CA5AF;
        font-size: 12px;
    }
    h4{
        margin-bottom: 6px;
    }
}
.product-card-content{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: auto;

    h4{
        display: flex;
        flex-direction: column;
        line-height: 1;
        text-align: start;
    }
}
.product-card-animation{
    //visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
    background: red;
    z-index: 10;
    height: 225px;
    width: 100%;

    img{
        //width: 50px;
        //height: 50px;
        height: 225px;
        object-fit: contain;
    }

    &.animation{
        //animation: flyToCartAnimation 1s ease-in-out infinite forwards;
    }
}

@keyframes flyToCartAnimation {
    0% {
        opacity: 1;
        position: fixed;
        transform: translate(0, 0) scale(1);
    }
    50% {
        //top: 80vh;
    }
    100% {
        opacity: 0;
        transform: translate(0, 0) scale(0.1);
    }
}

/*.product-card-animation {
    animation: flyToCartAnimation 0.5s ease-in-out forwards;
}*/



.product-state{
    background-color: var(--mainColor);
    color: #fff;
    border-radius: 27px;
    padding: 2px 8px;
    width: fit-content;
    font-size: 12px;
    height: fit-content;
}
.to-wishlist{
    position: absolute;
    top: 8px;
    right: 8px;
    background: rgba(255, 255, 255, 0.60);
    width: 40px;
    height: 40px;
    color: #333333;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 22px;
    cursor: pointer;

    &:hover{
        i{
            color: red;
            transform: scale(1.1);
        }
    }
    i{
        transition: .3s;
        margin-top: 3px;
    }
    .active{
        color: red !important;
    }

}
.product-counter{
    margin-top: auto;
    gap: 8px;

    .input-count-full{
        width: 100%;
        background: var(--mainColor);
        color: #fff;
        justify-content: space-around;

        .value{
            color: #fff;
        }
    }
    .product-counter-button{
        width: 52px;
        height: 42px;
        border-radius: 40px;
        background: #F2F2F2;
        font-size: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }
}
.fly-image {
    position: fixed;
    width: 50px; /* Adjust the size as needed */
    transition: transform 1s ease-in-out;
    z-index: 100;
}
  

@media(max-width: 767px){
    .product-card-content{
        .main-btn{
            font-size: 0;
            padding: 0 26px;
            background: #090909;
            &:before{
                transform: translateX(0);
                background: #090909;
                color: #F3F3F3;
                content: "\e91d";
                font-size: 20px;
            }
        }
    }
}
.favorites-slider{
    .slick-track{
        display: flex;
        gap: 30px;
        margin: 0;
    }
    .product-card-wrapper p{
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        display: -webkit-box;
        overflow: hidden;
    }
    .slick-prev:before, .slick-next:before{
        color: var(--mainColor);
        font-size: 28px;
    }
}
@media(max-width: 767px){
    .favorites-slider{
        .slick-track{
            gap: 15px;
        }
    }
    .slick-next{
        right: 0;
    }
    .slick-prev{
        left: -17px;
        z-index: 1;
    }
}