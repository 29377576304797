.mobile-fix-menu{
    position: fixed;
    height: 72px;
    bottom: 0;
    left: 0;
    width: 100vw;
    background: #fff;
    z-index: 10;
    padding: 12px 25px;
    justify-content: space-between;
    align-items: center;
    display: none
}
.mobile-fix-item{
    width: 48px;
    height: 48px;
    color: #A6A6A6;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    
    i{
        font-size: 24px;
        margin-bottom: 4px;
    }

    .cart-count{
        position: absolute;
        background: #A6A6A6;
        border-radius: 7.5px;
        border: 1px solid #FFF;
        color: #fff;
        top: -4px;
        right: 2px;
        width: 18px;
        height: 18px;
        font-size: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
    }
    &.active{
        color: var(--mainColor);
    }
    .mobile-fix-title{
        white-space: nowrap;
    }
}

@media(max-width: 767px){
    .mobile-fix-menu{
        display: flex;
    }
}