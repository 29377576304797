.stories-slider{
    .slick-prev:before, .slick-next:before{
        font-family: 'icomoon' !important;
        color: var(--accentColor);
        font-size: 50px;
    }
    .slick-prev, .slick-next{
        top: calc(50% - 25px);
    }
    .slick-prev:before{
        content: "\e90b";
    }
    .slick-next:before{
        content: "\e906";
    }
    .slick-track{
        display: flex;
        gap: 15px;
    }
    .slick-prev.slick-disabled{
        opacity: 0;
    }
    .slick-list{
        overflow-y: auto;
        padding: 24px 0 0;
    }
}
.stories-item{
    border-radius: 24px;
    border: 3px solid var(--accentColor);
    padding: 5px;
    cursor: pointer;
    transition: .3s;
    outline: none;
        
    img{
        height: 246px;
        width: 100%;
        object-fit: cover;
        border-radius: 16px;
        transition: .3s;
    }
    &:hover{
        transform: translateY(-24px);
    }
    &.visited{
        border: 3px solid #DDD;  
    }
}
.stories-modal{
    .modal-content{
        border: none;
        background: none;
    }
    .modal-dialog{
        max-width: 50vh;
    }
    .modal-body{
        padding: 0;
    }
    .styles_main__-0FEu{
        border-radius: 16px;
    }
    .Image-styles_image__gnfW1{
        border-radius: 16px;
    }
    .slick-prev:before, .slick-next:before{
        font-size: 50px;
        font-family: 'icomoon' !important;
        color: #fff;
    }
    .slick-prev {
        left: -50px;
    }
    .slick-prev:before{
        content: "\e908";
    }
    .slick-next:before{
        content: "\e903";
    }
}