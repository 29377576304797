.main-slider{
    overflow: hidden;
    height: 400px;
    
    .main-slider-item{
        padding: 0 15px;
        img{
            height: 400px;
        }
    }
}
.recent-slider{
    .slick-arrow{
        opacity: 0;
        transition: .3s;
        z-index: 1;
        background: #020202c9;
        color: #fff;
        width: 40px;
        height: 40px;
        border-radius: 50%;
    }
    &:hover{
        .slick-arrow{
            opacity: 1;
        } 
    }
    .slick-disabled{
        opacity: 0 !important;
    }
    .slick-next{
        right: 0;
    }
    .slick-prev{
        left: 0;
    }
    .slick-next:before{
        content: '\e906';
        font-family: 'icomoon' !important;
    }
    .slick-prev:before{
        content: '\e90b';
        font-family: 'icomoon' !important;
        color: #fff;
    }
}