.cart-devices{
    border-radius: 8px;
    background: #FFF;
    box-shadow: 0px -2px 12px 0px rgba(0, 0, 0, 0.05);
    padding: 12px 16px;
    margin: 0 16px 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .cart-devices-title{
        display: flex;
        align-items: center;
        color: #232323;
        font-size: 16px;
        font-weight: 600;

        i{
            font-size: 24px;
            color: var(--mainColor);
            margin-right: 16px;
        }
    }
    .input-count{
        height: 32px;
    }
}