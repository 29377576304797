.payments-items{
    margin-bottom: 32px;
    .accordion-item{
        border: none;

        &:not(:last-child){
            margin-bottom: 16px;
        }

        .after-none{
            .accordion-button::after{
                display: none;
            }
        }
    }
    .form-check-label{
        color: #232323;
        font-size: 16px;
        font-weight: 500;
        display: flex;
        align-items: center;
        input{
            width: 22px;
            height: 22px;
        }
    }
    .accordion-button{
        background: #F9F9F9;
        border-radius: 8px;
    }
    .accordion-item:last-of-type{
        border-radius: 8px;
    }
    .accordion-button:focus{
        box-shadow: none;
        border: none;
    }
    .accordion-item:first-of-type{
        border-radius: 8px;
    }
}
